import { useState, useEffect, Children } from "react";
import { useRouter } from "next/router";
import { Typography } from "antd";
const { Title, Text } = Typography;

export { ScreenGuard };

function ScreenGuard({ children }: any) {
  const [isAuthorized, setIsAuthorized] = useState<any>(null);

  useEffect(() => {
    handleResize(); // Initial check.

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function handleResize() {
    checkScreenSize();
  }

  function checkScreenSize() {
    if (window.innerWidth < 1100) {
      setIsAuthorized(false);
    } else {
      setIsAuthorized(true);
    }
  }

  if (isAuthorized === true || isAuthorized === null) {
    return children;
  } else {
    return (
      <div
        style={{
          width: "100%",
          textAlign: "center",
          top: "25vh",
          position: "fixed",
          padding: "5vw",
        }}
      >
        <Text type="secondary" style={{ fontSize: "5.5vw" }}>
          Sorry. Leadsnow is currently supported only on screens wider than{" "}
          <b>1100 pixels</b> :(
        </Text>
        <br />
        <Text type="secondary" style={{ fontSize: "5vw" }}>
          Please visit us on a larger screen.
        </Text>
      </div>
    );
  }
}
