export const PUBLIC_PATHS = [
    "/",
    "/login",
    "/signup",
    "/auth/forgot-password",
    "/auth/reset-password",
    "/auth/google",
    "/auth/logout",
    "/augh/expired",
    "/user/confirm",
    "/email/confirmed",
];
