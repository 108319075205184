import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import useUserStore from "../../zustand/useUserStore";
import { PUBLIC_PATHS } from "../../constants/routes";

export { RouteGuard };

function RouteGuard({ children }: any) {
  const token = useUserStore((state: any) => state.token);
  const router = useRouter();
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const expiresAt = useUserStore((state: any) => state.expiresAt);
  const setToken = useUserStore((state: any) => state.setToken);

  useEffect(() => {
    console.log("USEFFECTTT");
    // Whenever a route changes, do the route guard checks.
    // on initial load - run auth check
    checkUserAuthState(router.asPath);

    // // on route change complete - run auth check
    // router.events.on("routeChangeComplete", checkUserAuthState);

    // // unsubscribe from events in useEffect return function
    // return () => {
    //   router.events.off("routeChangeComplete", checkUserAuthState);
    // };
  }, [router.asPath]);

  const isTokenExpired = () => {
    if (!expiresAt) return true;

    return new Date(expiresAt) < new Date();
  };

  function checkUserAuthState(url: string) {
    const path = url.split("?")[0];

    const isPrivatePage = !PUBLIC_PATHS.includes(path);
    const isUserLoggedIn = token !== "" && !isTokenExpired();
    const isPublicPage = PUBLIC_PATHS.includes(path);

    if (isPublicPage && isUserLoggedIn) {
      setIsAuthorized(false);
      router.push("/find");
    } else if (isPrivatePage && !isUserLoggedIn) {
      setIsAuthorized(false);
      router.push("/auth/logout");
    } else {
      setIsAuthorized(true);
    }
  }

  if (isAuthorized) {
    return children;
  }

  return <></>;
}
