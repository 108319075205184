import create from "zustand";
import { persist } from "zustand/middleware";
export interface GoogleAccessToken {
  accessToken?: string | null;
  expiresAt?: Date | null;
}

export interface State {
  user: any;
  setUser: (newUser: any) => void;
  token: string;
  setToken: (token: string) => void;
  expiresAt: Date | null;
  setExpiresAt: (newExpiresAt: Date) => void;
  isRememberMe: boolean;
  setIsRememberMe: (newIsRememberMe: boolean) => void;
  googleAccessToken: GoogleAccessToken;
  setGoogleAccessToken: (
    accessToken: string | null,
    expiresAt: Date | null
  ) => void;
  subscription: any;
  setSubscription: (newData: any) => void;
}

const useUserStore = create<State>(
  persist(
    (set, get) => ({
      user: { email: "" },
      setUser: (newUser: any) => set((state: State) => ({ user: newUser })),
      token: "",
      setToken: (newToken: string) =>
        set((state: State) => ({ token: newToken })),
      expiresAt: null,
      setExpiresAt: (newExpiresAt: Date) =>
        set((state: State) => ({ expiresAt: newExpiresAt })),
      isRememberMe: false,
      setIsRememberMe: (newIsRememberMe: boolean) =>
        set((state: State) => ({ isRememberMe: newIsRememberMe })),
      googleAccessToken: { accessToken: "", expiresAt: null },
      setGoogleAccessToken: (
        accessToken: string | null,
        expiresAt: Date | null
      ) => set((state) => ({ googleAccessToken: { accessToken, expiresAt } })),
      subscription: {},
      setSubscription: (newData: any) => set((state: State) => ({ subscription: newData })),
    }),
    {
      name: "leadsnow-user",
    }
  )
);

export default useUserStore;
